.input-row {
  margin-bottom: 20px;
  position: relative;
  width: 100%;

  @include respond-to('small') {
    margin-bottom: 12px;
  }

  &-age p {
    color: $grey-50;
    font-size: $font-size-body-10;
    margin-top: 12px;

    html[dir='ltr'] & {
      text-align: left;
    }

    html[dir='rtl'] & {
      text-align: right;
    }
  }

  input {
    padding: 0 $input-left-right-padding;
    transition: border-color $short-transition, box-shadow $short-transition;

    &::placeholder {
      color: $input-placeholder-color !important;
      font-weight: $font-weight-body-20;
      opacity: 1;
    }

    &::-webkit-input-placeholder {
      // Fixes a problem in Safari/Fx for iOS where placeholder
      // text is not vertically centered and becomes cut-off.
      line-height: 1.3 !important;
    }

    // disable arrows on HTML5 number inputs
    &[type='number']::-webkit-inner-spin-button,
    &[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin: 0;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }

    &[type='email'],
    &[type='number'],
    &[type='password'],
    &[type='tel'],
    &[type='text'] {
      @include input-element();
    }

    html[dir='ltr'] & {
      padding: 0 11px 0 $input-left-right-padding;
    }

    html[dir='rtl'] & {
      padding: 0 $input-left-right-padding 0 11px;
      text-align: right;

      &:not([type='number']) {
        direction: ltr;
      }
    }

    &[type='checkbox']:hover,
    &[type='checkbox']:focus {
      border: 1px solid $input-border-color-focus;
    }

    &.unblock-code {
      text-transform: uppercase;

      &::placeholder {
        text-transform: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.recovery-key {
      font-size: 14px;
      text-transform: uppercase;

      @include respond-to('small') {
        font-size: 11px;
      }

      &::placeholder {
        text-transform: none;
      }
    }
  }
}
