@import '../../../../fxa-content-server/app/styles/breakpoints';
@import '../../../../fxa-content-server/app/styles/variables';

#dialogs {
  .modal {
    border-radius: 12px;
    padding: 25px 27px 30px;
    text-align: center;
    width: 545px;

    @include respond-to('small') {
      max-width: 420px;
      width: 100%;
    }
  }

  .dialog-icon {
    align-items: center;
    background: #20123a;
    border-radius: 8px;
    display: flex;
    height: 64px;
    justify-content: center;
    margin: 0 auto;
    padding: 16px;
    width: 64px;
  }

  .reactivate-subscription-success {
    font-weight: 600;
  }

  .message {
    margin-bottom: 0;
  }

  .settings-button {
    background: $button-background-color-primary;
    color: $button-text-color-primary;
    margin-top: 30px;

    &:hover:enabled {
      background: $button-background-color-primary-hover;
    }

    &:active:enabled {
      background: $button-background-color-primary-active;
    }
  }
}
