#legal-header {
  h2 {
    margin: 0 0 30px 0;
    padding: 0;
  }

  h3 {
    font-size: $small-font;
    padding: 5px 0 10px;
  }
}

#legal-footer {
  align-items: center;
  display: flex;
  height: 96px;

  @include respond-to('big') {
    justify-content: flex-end;
    padding: 0 32px;

    a {
      padding: 0 16px;

      &.privacy {
        margin-right: -16px;
      }
    }
  }

  @include respond-to('small') {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;

    a {
      padding: 8px;
    }
  }
}

#legal-copy {
  line-height: 24px;

  html[dir='ltr'] & {
    text-align: left;
  }

  html[dir='rtl'] & {
    text-align: right;
  }

  p {
    font-size: $base-font;
  }

  ol,
  ul {
    margin-left: 0;
    padding-left: 20px;
  }
}
