.modal {
  border-radius: $big-border-radius;
  box-shadow: $card-box-low;

  @include respond-to('big') {
    padding: 30px;
    width: 420px;
  }

  @include respond-to('small') {
    max-width: 420px;
    padding: 10px;
    width: 100%;
  }

  .modal-panel {
    text-align: center;

    h1,
    h2 {
      font-weight: normal;
    }

    &.connect-another-device {
      font-size: 16px;

      h1 {
        font-size: 18px;
      }
    }

    .modal-success {
      display: block;
      font-size: $base-font;
      margin: 0 auto;
    }
  }
}
