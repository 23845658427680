.support {
  .settings-unit:first-child {
    display: block;
  }
}

.support-form {
  margin: 25px 32px;

  @include respond-to('small') {
    margin: 25px 16px;
  }

  header {
    margin-bottom: 20px;

    h3 {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .support-field {
    @include respond-to('small') {
      padding: 0;
    }

    &:nth-child(3) {
      margin-bottom: 30px;
    }
  }

  textarea {
    @include input-element();
    display: inline-block;
    height: 120px;
    padding: $input-left-right-padding;
    resize: vertical;

    html[dir='rtl'] & {
      direction: ltr;
      text-align: right;
    }

    @include respond-to('small') {
      height: 101px;
    }
  }

  .button-row {
    .settings-button {
      height: 48px;
    }
  }

  .form-label {
    margin-bottom: 5px;

    label {
      color: $grey-50;
      font-size: 14px;
    }
  }
}

.modal {
  h2 {
    font-size: 18px;
    font-weight: 600;
  }

  h2,
  .message {
    text-align: center;
  }

  .message {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 10px;
    margin-bottom: 20px;

    p {
      margin-bottom: 0;
    }
  }

  .center-text {
    display: inline-grid;
    align-content: center;
  }

  &.dialog-error,
  &.dialog-success {
    display: inline-block;
    padding: 25px 27px;
    width: 545px;

    @include respond-to('small') {
      max-width: 420px;
      width: 100%;
    }

    .close-modal {
      background-image: image-url('close.svg');
      background-position: center center;
      color: #0c0c0d;
      font-size: 16px;
      font-weight: 600;
      height: 24px;
      right: 15px;
      text-indent: 0;
      top: 13px;
      width: 24px;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
