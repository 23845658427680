.tooltip {
  background: $error-background-color;
  border-radius: $small-border-radius;
  color: $message-text-color;
  padding: 5px 12px;
  position: absolute;
  top: -32px;
  z-index: 5;

  html[dir='ltr'] & {
    left: 3px;
  }

  html[dir='rtl'] & {
    right: 3px;
  }

  a {
    color: $message-text-color;
    text-decoration: underline;
  }

  /* tooltip caret */
  &::before {
    background: $error-background-color;
    bottom: -8px;
    content: '.';
    height: 16px;
    position: absolute;
    text-indent: -999px;
    transform: rotate(45deg);
    white-space: nowrap;
    width: 16px;
    // The z-index must be -1 or else the caret is displayed on top of the tooltip text
    z-index: -1;

    html[dir='ltr'] & {
      left: 12px;
    }

    html[dir='rtl'] & {
      right: 12px;
    }
  }

  &.tooltip-suggest {
    background: $button-background-color-default;

    &::before {
      background-color: $button-background-color-default;
    }

    > #email-suggestion,
    > .dismiss {
      cursor: pointer;

      &:focus {
        border-color: $color-white;
        border-style: dotted;
        border-width: 1px;
        color: $color-white;
      }
    }

    > #email-suggestion {
      text-decoration: underline;
    }

    > .dismiss {
      margin-left: 5px;
    }
  }

  &.tooltip-error {
    background: $error-background-color;

    &::before {
      background-color: $error-background-color;
    }
  }

  &.tooltip-warning {
    background: $warning-background-color;

    &::before {
      background-color: $warning-background-color;
    }
  }
}

/**
 * these are caret's attached to tooltips that hang below the
 * input element. The tooltip's top is set via JS.
 */
html[dir='rtl'] .tooltip.tooltip-below::before,
.tooltip.tooltip-below::before {
  top: -7px;
}
