@use "sass:color";

@import './photon-colors.scss';

// Font Variables
$default-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', sans-serif;
$header-font: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
  'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;

$font-size-body-10: 12px;
$font-weight-body-10: 400;

$font-size-body-20: 16px;
$font-weight-body-20: 400;

$font-size-caption-10: 12px;
$font-weight-caption-10: 400;

$font-size-title-30: 24px;
$font-weight-title-30: 400;

$font-size-title-20: 20px;
$font-weight-title-20: 400;

$font-size-title-10: 16px;
$font-weight-title-10: 400;

//Default color variables
$color-blue: $blue-50;
$color-blue-dark: $blue-60;
$color-blue-darker: $blue-70;
$color-blue-darkest: $blue-80;
$color-black: $grey-90;

$color-green-lightest: #e2fddc;
$color-green: $green-50;
$color-green-dark: $green-60;
$color-green-darker: $green-70;
$color-green-darkest: $green-80;

$color-grey-lightest: $grey-10;
$color-grey-lighter: $grey-20;
$color-grey-light: $grey-30;
$color-grey-spinner: $grey-30;
$color-grey: $grey-40;
$color-grey-disabled: $grey-40;
$color-grey-dark: $grey-50;
$color-grey-faint-text: $grey-50;
$color-grey-text: $grey-90;

$color-orange: $orange-50;

$color-red: $red-60;

$color-white: #fff; // White

$card-box-high: 0 12px 18px 2px rgba(34, 0, 51, 0.04),
  0 6px 22px 4px rgba(7, 48, 114, 0.12), 0 6px 10px -4px rgba(14, 13, 26, 0.12);

$card-box-low: 0 0 0 1px rgba(32, 18, 58, 0.04),
  0 1px 2px 0 rgba(34, 0, 51, 0.04), 0 2px 1px -1px rgba(7, 48, 114, 0.12),
  0 1px 6px 0 rgba(14, 13, 26, 0.12);

// Button variables
$button-border-radius-default: 6px;
$button-font-size-default: 16px;
$button-font-weight-default: 700;
$button-height-default: 48px;

$button-border-radius-settings: 6px;
$button-font-size-settings: 16px;
$button-font-weight-settings: 700;
$button-height-settings: 40px;
$button-height-settings-small: 36px;

$button-background-color-default: rgba($grey-90, 0.1);
$button-background-color-default-hover: rgba($grey-90, 0.2);
$button-background-color-default-active: rgba($grey-90, 0.3);

$button-background-color-primary: $blue-60;
$button-background-color-primary-hover: $blue-70;
$button-background-color-primary-active: $blue-80;

$button-text-color-default: $grey-90;
$button-text-color-primary: #fff;

$content-background-color: $color-white;
$cropper-background-color: $color-black;
$faint-text-color: $color-grey-faint-text;
$horizontal-rule-color: color.adjust($color-grey-light, $lightness: -5);
$html-background-color: hsl(240, 60%, 99%);

$header-color: rgb(32, 18, 59);

// Input element variables
$input-height: 48px;
$input-height-small: 40px;
$input-border-radius: 4px;
$input-inner-border-radius: 3px;

$input-background-color-default: $color-white;

// 16px is the minimum input element font size needed
// to prevent iOS from zooming in on a page when
// clicking on an input element.
$input-text-font-size-default: 16px;
$input-text-font-weight-default: $font-weight-body-20;

$input-left-right-padding: 16px;
$input-placeholder-color: rgba($grey-90, 0.4);

$input-border-color-default: rgba($grey-90, 0.3);
$input-border-color-focus: $blue-50;
$input-border-color-hover: rgba($grey-90, 0.5);

$input-text-color-default: $color-grey-text;
$input-text-color-disabled: $color-grey-faint-text;

$link-color-default: $blue-60;
$link-color-hover: $blue-60;
$link-color-active: $blue-70;
$link-color-focus: $blue-60;

$link-color-extra: $grey-50;

$marketing-border-color: $color-grey-spinner;
$message-text-color: $color-white;
$mobile-html-background-color: $color-white;

$secondary-button-background-color: $color-grey-lightest;
$secondary-button-border-color: color.adjust($color-grey, $lightness: 5);

$show-password-background-color: $color-white;
$show-password-focus-label-color: $color-white;
$show-password-label-color: $color-grey-faint-text;

// Message box variables
$success-background-color: #c7fbbf;
$success-text-color: #063311;

// the photon styleguide says info boxes should be grey instead of blue
$info-background-color: $grey-20;
$info-text-color: $grey-90;

$warning-background-color: $yellow-50;
$warning-text-color: $yellow-90;

$error-background-color: $red-60;
$error-background-color-hover: #a4000f;
$error-background-color-active: #5a0002;
$error-text-color: $color-white;

$text-color: rgb(32, 18, 59);

//Modal colors
$modal-border-color: $color-black;

//Account recovery modal colors
$modal-recovery-key-border: #e6e6e6;
$modal-recovery-key-background: #fbfbfb;
$modal-recovery-key-color: #4b4955;
$modal-recovery-key-option-border-color: #7c7c7c;

//Settings Specific Colors
$settings-header-border-bottom: rgba($grey-90, 0.1);
$settings-section-border: rgba($grey-90, 0.1);

//Radio Button Colors
$radio-border-default-color: $grey-40;
$radio-border-dot-color: #0097d0;
$radio-box-shadow-color: #55b8eb;

//Radio Button Variables
$radio-height: 10px;
$radio-width: 10px;
$radio-padding: 4px;
$radio-margin: 5px;

//Reset Password Colors
$reset-pw-warning: #d70022;

//Font-Size Variables
$large-font: 24px;
$medium-font: 18px;
$base-font: 14px;
$small-font: 12px;
$media-adjustment: 2px;

//Border-Radius Variables
$inner-button-border-radius: 1px;
$small-border-radius: 2px;
$big-border-radius: 16px;

//Animation Variables
$short-transition: 150ms;
$medium-transition: $short-transition * 3;
$long-transition: $short-transition * 7;

//Settings UI Height Hack
$settings-ui-height: 630px;

//Main Content Padding Top
$main-content-padding-top: 82px;
$main-content-padding-top-desktop: 70px;

//Avatar Variables
$avatar-size: 240px;
$avatar-spinner-color: $color-grey-spinner;
$profile-image-fade-in-duration: 150ms;
$avatar-border-color: hsla(261, 53%, 15%, 0.2);

// The Firefox logo
$fox-logo-zindex: 1;

$recovery-code-color: #666;
$recovery-code-background-color: #fbfbfb;
$recovery-code-border-color: #e6e6e6;

$image-url-path: '/images/' !default;

@function image-url($url) {
  @return url('#{$image-url-path}#{$url}');
}
