// Border box all the things by default
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  background-color: $html-background-color;
  height: 100%;
}

body {
  @include font();
  align-items: center;
  color: $text-color;
  display: flex;
  flex-direction: column;
  font-size: $base-font;
  justify-content: center;
  min-height: 100vh;
  padding: 2rem 0 0;

  &.settings {
    justify-content: flex-start;
    padding-top: 0;

    & .mozilla-header {
      display: none;
    }
  }

  @include respond-to('small') {
    justify-content: flex-start;
    padding: 0;
  }
}

noscript {
  top: 10%;

  .js & {
    display: none;
  }
}

.browsehappy {
  display: block;
  top: 25%;
}

noscript,
.browsehappy {
  color: $error-text-color;
  display: block;
  margin: 0 auto;
  position: absolute;
  text-align: center;
  width: 100%;

  a {
    color: $error-text-color;
  }
}

a {
  border-radius: 2px; // Give the focusring rounded corners
  color: $link-color-default;
  cursor: pointer; // Use the correct cursor for anchors without an href
  text-decoration: none;

  @include anchor-default-pseudo-classes();
}

button,
input {
  @include font();
  font-weight: inherit;
}

button.link {
  color: $link-color-default;
  font-size: 14px;
  height: 20px;
  justify-content: left;
  letter-spacing: 0;
  width: auto;
}

button.link:hover {
  text-decoration: underline;
}

input[type='radio']:not(.input-radio) {
  @include font();
  // autoprefixer does not handle appearance, see https://github.com/ai/autoprefixer/issues/205
  -moz-appearance: none;
  -ms-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: $color-white;
  border: 1px solid $radio-border-default-color;
  border-radius: $radio-width;
  box-sizing: content-box;
  font-size: $medium-font;
  height: $radio-height;
  margin: $radio-margin;
  outline: none;
  padding: $radio-padding;
  vertical-align: middle;
  width: $radio-width;

  @include respond-to('small') {
    font-size: $base-font + $media-adjustment;
  }

  &:active,
  &:focus {
    box-shadow: 0 0 0 2px $radio-box-shadow-color;
  }

  &:checked {
    background: $radio-border-dot-color;
    background-clip: content-box;
  }

  &:hover {
    border: 1px solid $radio-border-dot-color;
  }
}

// Add the clearfix class to an element that contains
// floated elements to give the element the height
// of the tallest floated element. This fixes layout
// problems such as text appearing under or between
// two floated buttons.
.clearfix::after {
  clear: both;
  content: ('.');
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

* html .clearfix {
  height: 1%;
}

.step {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin: 0 auto 20px;

  $step-height: 18px;
  $step-width: 141px;

  @include respond-to('big') {
    height: $step-height;
    width: $step-width;
  }

  @include respond-to('small') {
    height: $step-height * 0.75;
    width: $step-width * 0.75;
  }
}
