#loading-spinner img {
  animation: 0.9s spin infinite linear;
  display: block;
  height: 36px;
  left: calc(50% - 18px);
  overflow: hidden;
  position: absolute;
  text-indent: 100%;
  top: calc(50% - 18px);
  white-space: nowrap;
  width: 36px;
}

#loading-template {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}

.spinner {
  @include hidpi-background-image('spinnerlight', 36px 36px);
  animation: 0.9s spin infinite linear;
  background-repeat: no-repeat;
  display: block;
  height: 36px;
  margin: 130px auto 0;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  width: 36px;

  @include respond-to('small') {
    margin-top: 95px;
  }

  #loading-template & {
    margin: 0;
  }

  .button-row &,
  .button-flex-row &,
  .input-row & {
    background-image: url('/images/spinnerwhite.svg');
    background-size: 33px 33px;
    height: 33px;
    margin: 0 auto;
    position: relative;
    width: 33px;

    @include respond-to('small') {
      top: -1px;
    }
  }

  &.spinner-settings-fetch {
    @include hidpi-background-image('spinnergrey', 25px 25px);
    height: 25px;
    margin: 0 auto;
    width: 25px;
  }
}
