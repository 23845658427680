// After all .button-rows are converted to TW, we can remove this entire file
.button,
#main-content button {
  @include font();
  align-items: center;
  background: $button-background-color-default;
  border: 0;
  border-radius: $button-border-radius-default;
  color: $button-text-color-default;
  cursor: pointer;
  display: inline-flex;
  font-size: $button-font-size-default;
  font-weight: $button-font-weight-default;
  height: $button-height-default;
  justify-content: center;
  padding: 0 8px;
  transition-duration: $short-transition;
  transition-property: background-color;
  // it is very easy to accidentally select the text when clicking
  -webkit-touch-callout: none;
  // user select not supported by autoprefixer
  // https://github.com/postcss/autoprefixer/issues/167
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;

  &:hover {
    background: $button-background-color-default-hover;
  }

  &:active {
    background: $button-background-color-default-active;
  }

  &:focus {
    box-shadow: 0 0 0 1px #0a84ff inset, 0 0 0 1px #0a84ff,
      0 0 0 4px rgba(10, 132, 255, 0.3);
  }

  &:focus:active {
    text-decoration: none;
  }

  &[type='submit']:not(.secondary-button),
  &.primary-button {
    background: $button-background-color-primary;
    color: $button-text-color-primary;

    &:hover:enabled {
      background: $button-background-color-primary-hover;
    }

    &:active:enabled {
      background: $button-background-color-primary-active;
    }
  }

  &.tertiary-button {
    background: transparent;
    color: $blue-60;

    &:hover {
      color: $blue-70;
    }
    &:active {
      color: $blue-80;
    }
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.4;

    &:not(.primary-button):not([type='submit']) {
      background: $button-background-color-default;
    }
  }
}

.button-row {
  margin: 0 0 20px;

  .button {
    text-decoration: none;
  }
}
