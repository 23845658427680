@import '../../../../fxa-content-server/app/styles/breakpoints';

// HACK: create-react-app forbids using images outside the root level of the
// project, so let's use local copies from fxa-content-server for now

#about-mozilla {
  background-image: url('./images/mozilla.svg');
}

.spinner {
  background-image: url('./images/spinnerlight.svg');

  .button-row &,
  .input-row & {
    background-image: url('./images/spinnerwhite.svg');
  }

  .secondary-button &,
  &.spinner-settings-fetch {
    background-image: url('./images/spinnerlight.svg');
  }
}

.breadcrumbs {
  display:flex;
  margin-top: 0;
  padding-left: 32px;
  width: 100%;

  @include respond-to('simpleSmall') {
    margin-bottom: 0;
    margin-left: 0;
    padding: 0;
  }

  li {
    list-style: none;
    margin: 0;
    padding: 0;

    &::before {
      content: '\003E';
      padding: 0 10px;

      @include respond-to('simpleSmall') {
        padding: 0 5px;
      }
    }

    &:first-child::before {
      content: '';
      padding: 0;
    }
  }
}
