.error {
  #loading-template & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .loading & {
    // there is no header on the loading screen. Push the text down slightly.
    margin-top: 20px;
  }
}

.success {
  @include message-box($success-background-color, $success-text-color);
  display: none;
  margin-bottom: 10px;
}

.warning {
  @include message-box($warning-background-color, $warning-text-color);
}

.progress-hidden {
  display: none;
}

.reset-warning {
  font-size: $base-font;
  font-weight: normal;
  line-height: 1.6em;
  margin-top: 0;
  padding: 5px 25px 15px 25px;

  @include respond-to('small') {
    padding: 5px;
  }

  span {
    color: $reset-pw-warning;
  }
  &.reset-warning-complete-password {
    margin-bottom: 25px;
  }
}

// Put a margin on top when the error messages follow a div
div + .success,
div + .error {
  margin-top: 10px;
}

/* Animation triggering classes */
.shake {
  animation: shake $long-transition;
}

.fadein {
  animation: fadein $long-transition;
}

.fade-in-forward {
  animation: fadein $short-transition forwards;
}

.fade-down-tt {
  animation: fade-down-tt $medium-transition;
}

.fade-up-tt {
  animation: fade-up-tt $medium-transition;
}

.pulse {
  animation: pulse $medium-transition;
}

/* Keyframes */
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

//used to spin loader elements
@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes fade-up {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-15px);
  }
}

//used for tooltips below form element
@keyframes fade-up-tt {
  0% {
    opacity: 0;
    transform: translateY(15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

//used for tooltips above form element
@keyframes fade-down-tt {
  0% {
    opacity: 0;
    transform: translateY(-15px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

//used for pulsing checkbox
@keyframes pulse {
  0% {
    opacity: 0;
    transform: scale(1, 1);
  }

  50% {
    opacity: 1;
    transform: scale(1.1, 1.1);
  }

  100% {
    opacity: 1;
    transform: scale(1, 1);
  }
}

//used for bringing  logo into position
@keyframes fade-down-logo {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

//used to add shake to error/success message
@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translateX(-4px);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translateX(4px);
  }
}
